import Account from '@/models/AccountDetails/Account'

export default class AccountFactory {
  static createFromJson(json) {
    return new Account(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(AccountFactory.createFromJson(item))
    })

    return jsonData
  }
}
